<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="vertical">
          <a-row :gutter="48">

            <a-col
              :md="5"
              :sm="24"
            >
              <!-- <a-form-item
                label="天气信息"
                prop="weatherText"
              >
                <a-input
                  v-model="queryParam.weatherText"
                  placeholder="请输入天气信息"
                  allow-clear
                />
              </a-form-item> -->
              <a-range-picker
                v-model="value1"
                style="margin-top:28px;"
                @change="handleTime"
              />
            </a-col>
            <a-col
              :md="5"
              :sm="24"
            >
              <a-form-item
                label="设备名称"
                prop="devName"
              >
                <a-input
                  v-model="queryParam.devName"
                  placeholder="请输入设备名称"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <a-col
              :md="5"
              :sm="24"
              v-if="role !== 'GS' && role !== 'YG'"
            >
              <a-form-item
                label="公司"
                prop="tenantId"
              >
                <a-select
                  placeholder="请选择"
                  v-model="queryParam.tenantId"
                  style="width: 100%"
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  allow-clear
                >
                  <a-select-option
                    v-for="(d, index) in tenant"
                    :key="index"
                    :value="d.id"
                  >{{ d.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              :md="5"
              :sm="24"
              v-if="advanced"
            >
              <a-form-item
                label="最小车速"
                prop="minSpeed"
              >
                <a-input
                  v-model="queryParam.minSpeed"
                  placeholder="请输入最小车速"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <a-col
              :md="5"
              :sm="24"
              v-if="advanced"
            >
              <a-form-item
                label="最大车速"
                prop="maxSpeed"
              >
                <a-input
                  v-model="queryParam.maxSpeed"
                  placeholder="请输入最大车速"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <a-col
              :md="5"
              :sm="24"
              v-if="advanced"
            >
              <a-form-item
                label="是否超速"
                prop="warningText"
              >
                <a-select
                  placeholder="请选择"
                  v-model="queryParam.warningText"
                  style="width: 100%"
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  allow-clear
                >
                  <a-select-option
                    v-for="(d, index) in options"
                    :key="index"
                    :value="d.id"
                  >{{ d.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              :md="!advanced && 8 || 24"
              :sm="24"
            >
              <span
                class="table-page-search-submitButtons"
                :style="advanced && { float: 'right', overflow: 'hidden' } || {}"
              >
                <a-button
                  type="primary"
                  @click="handleQuery"
                ><a-icon type="search" />查询</a-button>
                <a-button
                  style="margin-left: 8px;margin-top:28px;"
                  @click="resetQuery"
                ><a-icon type="redo" />重置</a-button>
                <a-button
                  type="primary"
                  style="margin-left: 8px;margin-top:28px;"
                  v-hasPermi="['gx:devicerecord:export']"
                  @click="handleExport"
                >
                  导出
                </a-button>
                <a-button
                  type="primary"
                  v-print="'#printContent'"
                  style="margin-left: 8px;margin-top:28px;"
                >
                  打印
                </a-button>
                <a
                  @click="toggleAdvanced"
                  style="margin-left: 8px"
                >
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
                <!-- <a-button
                  type="primary"
                  @click="$refs.createForm.handleAdd()"
                  v-hasPermi="['gx:devicetype:add']"
                  style="margin-left: 8px;margin-top:28px;"
                >
                  <a-icon type="plus" />新增
                </a-button> -->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!-- <a-button
          type="primary"
          :disabled="single"
          @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['tl:devicemodel:edit']"
        >
          <a-icon type="edit" />修改
        </a-button>
        <a-button
          type="danger"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['tl:devicemodel:remove']"
        >
          <a-icon type="delete" />删除
        </a-button> -->

        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"
        />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :components="drag(columns)"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
        id="printContent"
      >
        <span
          slot="createTime"
          slot-scope="text, record"
        >
          {{ parseTime(record.createTime) }}
        </span>
        <span
          slot="warningText"
          slot-scope="text, record"
        >
          <a-tag
            color="green"
            v-if="record.warningText == 0"
          >未超速</a-tag>
          <a-tag
            color="red"
            v-else
          >超速</a-tag>
        </span>
        <span
          slot="operation"
          slot-scope="text, record"
        >
          <a
            @click="$refs.createForm.handleUpdate(record.id, undefined)"
            v-hasPermi="['gx:devicerecord:query']"
          >
            <a-icon type="edit" />详情
          </a>
          <a-divider
            type="vertical"
            v-hasPermi="['gx:devicerecord:remove']"
          />
          <a
            @click="handleDelete(record)"
            v-hasPermi="['gx:devicerecord:remove']"
          >
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageVehicle, delVehicle } from '@/api/project/vehicle'
import CreateForm from './modules/CreateForm'
import { listCompany } from '@/api/project/company'
import tableDragResize from '@/utils/tableDragResize'
import { tableMixin } from '@/store/table-mixin'
import { mapGetters } from 'vuex'
export default {
  name: 'DeviceType',
  components: {
    CreateForm
  },
  props: ['devId'],
  mixins: [tableMixin, tableDragResize],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      value1: [],
      selectedRows: [],
      tenant: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      role: '',
      // 查询参数
      queryParam: {
        sortField: 'createTime',
        sortOrder: 'DESCEND',
        name: null,
        tenantId: undefined,
        pageNum: 1,
        pageSize: 10
      },
      options: [{ id: 0, name: '否' }, { id: 1, name: '超速' }],
      columns: [
        {
          title: '所属部门',
          dataIndex: 'tenantName',
          width: 160,
          align: 'center'
        },
        {
          title: '是否超速',
          dataIndex: 'warningText',
          width: 120,
          scopedSlots: { customRender: 'warningText' },
          align: 'center'
        },
        {
          title: '设备名称',
          dataIndex: 'devName',
          width: 120,
          ellipsis: true,
          align: 'center'
        },
        {
          title: '设备序列号',
          dataIndex: 'devCode',
          width: 120,
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '环境温度',
        //   dataIndex: 'ambientTemperature',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '放电电流',
        //   dataIndex: 'dischargeCurrent',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '总发电量',
        //   dataIndex: 'electricQuantityAll',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '电池电压',
        //   dataIndex: 'cellVoltage',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '充电电流',
        //   dataIndex: 'chargeCurrent',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '总用电量',
        //   dataIndex: 'electricQuantityHave',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '当日发电量',
        //   dataIndex: 'electricQuantityNowDay',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '来车方向',
          dataIndex: 'nowCarDirection',
          ellipsis: true,
          width: 120,
          align: 'center'
        },
        {
          title: '来车数量',
          dataIndex: 'nowCarNum',
          ellipsis: true,
          width: 120,
          align: 'center'
        },
        {
          title: '过车速度',
          dataIndex: 'nowCarSpeed',
          ellipsis: true,
          width: 120,
          align: 'center'
        },
        {
          title: '来车时间',
          dataIndex: 'createTime',
          width: 160,
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 200,
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.queryParam.devId = this.devId
    this.getList()
    this.role = this.roles[0]
    if (this.role !== 'GS' && this.role !== 'YG') {
      listCompany().then(res => {
        this.tenant = res.data
      })
    } else {
      this.queryParam.tenantId = this.tenantIds
    }
  },
  computed: {
    ...mapGetters([
      'roles',
      'tenantIds'
    ])
  },
  watch: {
    devId: {
      handler (val, oldVal) {
        // console.log(val, 'valvalval')
        this.queryParam.devId = val
        if (val) {
          this.getList()
        }
      },
      immediate: true
    }
  },
  methods: {
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    /** 查询型号信息列表 */
    getList () {
      this.loading = true
      pageVehicle(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    handleTime (e) {
      this.queryParam.createBeginTime = e[0].format('YYYY-MM-DD')
      this.queryParam.createEndTime = e[1].format('YYYY-MM-DD')
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        sortField: 'createTime',
        sortOrder: 'DESCEND',
        name: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.value1 = []
      this.queryParam.devId = this.devId
      if (this.role === 'GS') {
        this.queryParam.tenantId = this.tenantIds
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        // content: '确认删除所选中数据?',
        onOk () {
          const data = []
          that.ids.map(id => data.push({ 'id': id }))
          return delVehicle(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel () { }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('gx/gx-device-record/export', {
            ...that.queryParam
          }, `来车记录_${new Date().getTime()}.xlsx`)
        },
        onCancel () { }
      })
    }
  }
}
</script>
